<!--
 * @Author: huadan
 * @Date: 2021-04-09 15:15:12
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2021-08-19 11:41:22
 * @Description: 修改资料
-->
<style lang="less" scoped>
.modify-userInfo {
  display: flex;
  flex-direction: column;

  .title {
    font-size: @font_size_6;
    font-weight: bold;
    margin: 30px 0;
  }

  .sex-radio {
    &:first-child {
      margin-right: 70px;
    }
  }
}
</style>

<template>
  <NeoPageLayout class="modify-userInfo">
    <a-row class="title" type="flex" justify="center">
      <a-col :span="8">{{ $t('modifyUserInfo.title') }}</a-col>
    </a-row>
    <!--  修改资料 -->
    <a-form-model ref="form" :model="form" :label-col="labelCol" :wrapper-col="wrapperCol" :rules="rules">
      <!-- 用户名 -->
      <a-form-model-item :label="$t('modifyUserInfo.username')" prop="name">
        <a-input v-model="form.name" disabled />
      </a-form-model-item>
      <!-- 邮箱 -->
      <a-form-model-item :label="$t('email')" prop="email">
        <a-input v-model="form.email" disabled />
      </a-form-model-item>
      <!-- 性别 -->
      <!-- <a-form-model-item :label="$t('modifyUserInfo.sex')" prop="sex">
        <a-radio-group v-model="form.sex">
          <a-radio class="sex-radio" v-for="item in sexList" :key="item.dictValue" :value="item.dictValue">{{
            item.dictLabel
          }}</a-radio>
        </a-radio-group>
      </a-form-model-item> -->
      <!-- 手机 -->
      <a-form-model-item :label="$t('modifyUserInfo.phone')" prop="phone">
        <a-input tupe="number" v-model="form.phone" :max-length="11" />
      </a-form-model-item>
      <!-- 时区 -->
      <!-- <a-form-model-item :label="$t('modifyUserInfo.timezone')" prop="timeZone" v-if="$role('user_userInfo_customer')">
        <a-select v-model="form.timeZone">
          <a-select-option v-for="item in timeZoneList" :key="item.dictValue" :value="item.dictValue">
            {{ item.dictLabel }}
          </a-select-option>
        </a-select>
      </a-form-model-item> -->
      <!-- 部门 -->
      <!-- <a-form-model-item
        :label="$t('modifyUserInfo.department')"
        prop="department"
        v-if="$role('user_userInfo_provider')"
      >
        <a-input v-model="form.department" />
      </a-form-model-item> -->
      <!-- 公司实体 -->
      <a-form-model-item :label="$t('modifyUserInfo.entity')" prop="entity">
        <a-input v-model="form.entityName" disabled />
      </a-form-model-item>
      <!-- 产品线 -->
      <a-form-model-item :label="$t('modifyUserInfo.line')" prop="line">
        <a-input v-model="form.line" type="textarea" disabled />
      </a-form-model-item>
      <a-form-model-item :wrapper-col="{ span: 8, offset: 9 }">
        <a-button type="primary" @click="onSubmit">{{ $t('modifyPassword.confirm_modify') }}</a-button>
        <!-- 点击”取消“回到首页 -->
        <a-button style="margin-left: 10px" @click="cancel">{{ $t('cancel') }}</a-button>
      </a-form-model-item>
    </a-form-model>
  </NeoPageLayout>
</template>
<script>
import { mapState } from 'vuex'
import store from '@/store'
import { Icon } from 'ant-design-vue'

// const { validate } = window.$g

export default {
  name: 'modifyUserInfo',
  data() {
    return {
      labelCol: { span: 9 },
      wrapperCol: { span: 6 },
      //用户信息
      form: {
        name: '',
        email: '',
        phone: '',
        entityName: '',
        line: '',
      },
      //规则
      rules: {},
    }
  },

  computed: {
    ...mapState('app', {
      // 用户信息
      userInfo: (state) => state.userInfo,
    }),
    // ...mapMutations('app', {
    //   update: 'updateAppState',
    // }),

    // 性别字典表数据
    // sexList() {
    //   return this.$store.getters['app/getDictByType']('gender')
    // },

    // 时区字典表数据
    // timeZoneList() {
    //   return this.$store.getters['app/getDictByType']('TIME_ZONE')
    // },
  },

  mounted() {
    this.fetch()
  },

  methods: {
    //获取用户信息
    async fetch() {
      try {
        this.loading = true
        const data = await this.$http({ 1: 'companyInfo', 3: 'customerInfo' }[this.userInfo.type])
        this.form = { ...data, line: data.productLineNames != null ? data.productLineNames.join(',') : '' }
        this.loading = false
      } catch (err) {
        this.loading = false
        this.$httpNotify(err)
      }
    },

    //提交
    onSubmit() {
      this.$refs.form.validate(async (valid) => {
        if (valid) {
          try {
            this.loading = true
            await this.$http({ 1: 'updateCompanyInfo', 3: 'updateCustomerInfo' }[this.userInfo.type], this.form)

            //更新userInfo信息
            const updateInfo = { ...this.userInfo }
            updateInfo.mobile = this.form.phone
            //vuex
            store.commit('app/update', {
              userInfo: updateInfo,
            })
            this.loading = false
            this.$success({
              title: this.$t('modifyUserInfo.modify_success'),
              icon: (h) => h(Icon, { props: { type: 'check-circle', theme: 'filled' } }),
              content: this.$t('modifyUserInfo.user_modify_success'),
              okText: this.$t('confirm'),
              onOk: () => {
                if (this.$store.state.app.userInfo.superTenant === 1) {
                  this.$router.push({ name: 'employee' })
                } else {
                  this.$g.goHome()
                }
              },
            })
          } catch (err) {
            this.loading = false
            this.$httpNotify(err)
          }
        }
      })
    },
    cancel() {
      if (this.$store.state.app.userInfo.superTenant === 1) {
        this.$router.push({ name: 'employee' })
      } else {
        this.$g.goHome()
      }
    },
  },
}
</script>
